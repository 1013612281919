import React, { useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Dropdown } from 'components/UIExternal';
import countryNotFoundImage from 'assets/images/globalStructurePage/globalStructureCountryNotFound.svg';
import { GlobalStructureManagerProfile } from '../GlobalStructureManagerProfile';
import { managerProfiles } from './managers';
import {
  globalStructureFindManagerContainer,
  globalStructureFindManagerTitle,
  managerSearchInput,
  countryNotFoundContainer,
  contactUs,
} from './styles';

export const GlobalStructureFindManager = () => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const [managers, setManagers] = useState(managerProfiles);
  const { countries } = useLocations();

  const handleFilterManagers = value => {
    const result = value ? managerProfiles.filter(manager => manager.countryId === value?.id) : managerProfiles;
    setManagers(result);
  };

  const countryNotFound = (
    <div className={countryNotFoundContainer}>
      <img src={countryNotFoundImage} alt="country not found" />
      <div className="content">
        <p>{getText('couldNotFindCountry')}</p>
        <span className={contactUs}>{getText('contactUs')}:</span>
        compliance@dealshaker.com
      </div>
    </div>
  );

  const handleManagers = () => {
    const result = managers?.length ? managers?.slice(0, 4) : managers;
    return result.flatMap(({ country, managers }) =>
      managers.map(el => <GlobalStructureManagerProfile manager={{ ...el, country }} />),
    );
  };

  return (
    <div>
      {/* when we have two roles for manage. One for country and one for area */}
      {/* <h1 className={globalStructureFindManagerTitle}>{getText('findManagerRole', { role: getText(role) })}</h1> */}
      <h1 className={globalStructureFindManagerTitle}>{getText('findManager')}</h1>

      <div className={globalStructureFindManagerContainer(theme)}>
        <Dropdown
          withSearch
          placeholder={getText('typeCountry')}
          displayKey="name"
          uniqueKey="id"
          options={countries}
          onChange={handleFilterManagers}
          className={managerSearchInput(theme)}
        />
        <div className="managers">
          {!managers?.length && countryNotFound}
          {handleManagers()}
        </div>
      </div>
    </div>
  );
};
