// in model v1 we use the name of country for naming convention.
// Now we use manage name for that purpose. For better switch managers to  another country
import afganistan from 'assets/images/globalStructurePage/users-images/afganistan.png';
import algeria from 'assets/images/globalStructurePage/users-images/algeria.png';
import balticCountries1 from 'assets/images/globalStructurePage/users-images/balticCountries1.png';
import balticCountries2 from 'assets/images/globalStructurePage/users-images/balticCountries2.png';
import belize from 'assets/images/globalStructurePage/users-images/belize.png';
import bolivia from 'assets/images/globalStructurePage/users-images/bolivia.png';
import brazil from 'assets/images/globalStructurePage/users-images/brazil.png';
import cambodia from 'assets/images/globalStructurePage/users-images/cambodia.jpg';
import cameroon from 'assets/images/globalStructurePage/users-images/cameroon.png';
import colombia1 from 'assets/images/globalStructurePage/users-images/colombia1.png';
import colombia2 from 'assets/images/globalStructurePage/users-images/colombia2.png';
import colombia3 from 'assets/images/globalStructurePage/users-images/colombia3.png';
import coteDiVoire from 'assets/images/globalStructurePage/users-images/coteDiVoire.png';
import silviaRivera from 'assets/images/globalStructurePage/users-images/SILVIA_Rivera.jpg';
import elSalvador from 'assets/images/globalStructurePage/users-images/elSalvador.png';
import finland from 'assets/images/globalStructurePage/users-images/finland.png';
import france from 'assets/images/globalStructurePage/users-images/france.jpg';
import greece from 'assets/images/globalStructurePage/users-images/greece.png';
import hungary from 'assets/images/globalStructurePage/users-images/hungary.png';
import iceland from 'assets/images/globalStructurePage/users-images/iceland.png';
import india from 'assets/images/globalStructurePage/users-images/india.jpg';
import indonesia from 'assets/images/globalStructurePage/users-images/indonesia.png';
import iraq from 'assets/images/globalStructurePage/users-images/iraq.png';
import israelPalestine from 'assets/images/globalStructurePage/users-images/israel.jpg';
import italy1 from 'assets/images/globalStructurePage/users-images/italy1.png';
import renatoBagaroloItaly from 'assets/images/globalStructurePage/users-images/Renato_Bagarolo_Italy.png';
import renatoBagaroloMaltaSanMarino from 'assets/images/globalStructurePage/users-images/RENATO_BAGAROLO_Malta_SanMarino.jpg';
import italy3 from 'assets/images/globalStructurePage/users-images/italy3.png';
import italy4 from 'assets/images/globalStructurePage/users-images/italy4.png';
import italy5 from 'assets/images/globalStructurePage/users-images/italy5.png';
import japan from 'assets/images/globalStructurePage/users-images/japan.png';
import kazakhstan from 'assets/images/globalStructurePage/users-images/kazakhstan.png';
import kyrgyzstan from 'assets/images/globalStructurePage/users-images/kyrgyzstan.png';
import mexico from 'assets/images/globalStructurePage/users-images/mexico.png';
import rusuTatiana from 'assets/images/globalStructurePage/users-images/RUSU_TATIANA.jpg';
import morocco from 'assets/images/globalStructurePage/users-images/morocco.png';
import newZealand from 'assets/images/globalStructurePage/users-images/newZealand.png';
import nigeria from 'assets/images/globalStructurePage/users-images/nigeria.jpg';
import pakistan from 'assets/images/globalStructurePage/users-images/pakistan.png';
import paraguay from 'assets/images/globalStructurePage/users-images/paraguay.jpg';
import panama from 'assets/images/globalStructurePage/users-images/panama.png';
import peru from 'assets/images/globalStructurePage/users-images/peru.jpg';
import philippines from 'assets/images/globalStructurePage/users-images/philippines.png';
import poland from 'assets/images/globalStructurePage/users-images/poland.png';
import russia from 'assets/images/globalStructurePage/users-images/russia.png';
import CRISTI_CALINA from 'assets/images/globalStructurePage/users-images/CRISTI_CALINA.jpg';
import slovenia from 'assets/images/globalStructurePage/users-images/slovenia.png';
import ahnKwangja from 'assets/images/globalStructurePage/users-images/ahn-kwangja.png';
import spain1 from 'assets/images/globalStructurePage/users-images/spain1.png';
import spain2 from 'assets/images/globalStructurePage/users-images/spain2.png';
import swedenAndNorway1 from 'assets/images/globalStructurePage/users-images/swedenAndNorway1.jpg';
import swedenAndNorway2 from 'assets/images/globalStructurePage/users-images/swedenAndNorway2.png';
import tanzania from 'assets/images/globalStructurePage/users-images/tanzania.png';
import taiwan from 'assets/images/globalStructurePage/users-images/taiwan.png';
import thailand from 'assets/images/globalStructurePage/users-images/thailand.png';
import iltfatAliyev from 'assets/images/globalStructurePage/users-images/Iltfat-Aliyev.png';
import uganda from 'assets/images/globalStructurePage/users-images/uganda.png';
import ukraine from 'assets/images/globalStructurePage/users-images/ukraine.png';
import vietnam from 'assets/images/globalStructurePage/users-images/vietnam.png';
import yemen from 'assets/images/globalStructurePage/users-images/yemen.png';
import luFeng from 'assets/images/globalStructurePage/users-images/Lu_Feng.png';
import shangBoZhang from 'assets/images/globalStructurePage/users-images/Shang_Bo_Zhang.png';
import zacHuang from 'assets/images/globalStructurePage/users-images/Zac_Huang.png';
import predragRmus from 'assets/images/globalStructurePage/users-images/predrag-rmus.jpg';
import aliAbdulkadir from 'assets/images/globalStructurePage/users-images/ALI_ABDULKADIR.jpg';
import joseMendizabal from 'assets/images/globalStructurePage/users-images/jose-mendizabal.jpg';
import thuBrendaLe from 'assets/images/globalStructurePage/users-images/tu-brenda-le.jpg';
import ivanMarkCaribbean from 'assets/images/globalStructurePage/users-images/IvanMarkCaribbean.png';
import mustafaSabryEgypt from 'assets/images/globalStructurePage/users-images/MustafaSabryEgypt.png';
import philipWintherSoendergaard from 'assets/images/globalStructurePage/users-images/PhilipWintherSoendergaard.png';
import abelDiazEcuador from 'assets/images/globalStructurePage/users-images/AbelDiazEcuador.png';
import eugenioRodriguesPortugal from 'assets/images/globalStructurePage/users-images/EugenioRodriguesPortugal.png';
import youngJuKim from 'assets/images/globalStructurePage/users-images/Young-Ju-Kim.png';
import bahaEryurekliler from 'assets/images/globalStructurePage/users-images/Baha-Eryurekliler.png';
import desmondBoateng from 'assets/images/globalStructurePage/users-images/Desmond-Boateng.png';
import sandraUnaBoakye from 'assets/images/globalStructurePage/users-images/Sandra-Una-Boakye.png';

// country, countryId, manager name, manager id, manager image
const data = [
  // to be shown by default
  ['Vietnam', 242, 'Mai Loan', '9e2eebd6-dbc8-450e-b441-89e787438802', vietnam],
  ['Mexico', 144, 'Marco Antonio Romero Reyes', '55a172e3-e1ec-4aeb-985a-0c7f0e37b513', mexico],
  ['Romania', 182, 'Cristi Calina', 'd3460269-0f2a-48d5-a3d1-3a3bc27da7f8', CRISTI_CALINA],
  ['Russia', 183, 'Alla Valieva', 'ca21334b-8526-4951-b56e-fd4e1009e6a3', russia],
  // the rest of managers
  ['Afghanistan', 1, 'Najibullah Adel', '8f209116-7327-4e28-ac56-aa3b9b8dadd3', afganistan],
  ['Albania', 3, 'Etleva Treska', '2bc388b9-e358-4be8-aa5e-e5ffa633b937'],
  ['Algeria', 4, 'Malika Nourine Elaid', '666f565a-cfe3-4bbb-a81d-f29cb95c4381', algeria],
  ['Argentina', 11, '', '82d08e35-50ca-4863-887c-4aac59c9007d'],
  ['Austria', 15, 'Wilhelm Enns', '8bfdc1b4-acc4-4408-9bfb-e00a8e8d7f37'],
  ['Azerbaijan', 16, 'Valentina Filimonova', '98c1057a-e9b6-401b-9609-e6f1a888b4f9'],
  ['Belarus', 21, 'Natalia Kaysina', 'ac0bd157-6cea-4f57-8ee8-df7778efeba7'],
  ['Belize', 23, 'Pepe Gutierrez', 'c18d1f9d-e140-11ea-bc15-005056890e85', belize],
  ['Bolivia', 27, 'Alberto Condor', 'd6d18868-a479-4ae7-b76c-a93ffed7913d', bolivia],
  ['Brazil', 32, 'Vanderlei Leandro', '3ca1d344-4546-492e-b375-6a323cda634c', brazil],
  ['Canada', 40, 'Thu Brenda Le', 'bdcefe03-4e99-4d98-8d91-95b4480eb296', thuBrendaLe],
  ['China', 46, 'Zac Huang', 'e96c3f81-e13f-11ea-bc15-005056890e85', zacHuang],
  ['China', 46, 'Lu Feng', 'd8556582-e13f-11ea-bc15-005056890e85', luFeng],
  ['China', 46, 'Shang Bo Zhang', '832c802b-2365-4cec-89d9-0eb0dc5d6663', shangBoZhang],
  ['Cambodia', 38, 'Chhum Sokneov', '70f3c297-524c-468a-a3d1-80936fe035ba', cambodia],
  ['Cameroon', 39, 'Raoul Letah', '7f20b105-d093-47f5-b7d1-35479f384502', cameroon],
  ['Chile', 45, 'Viviana Vega Montoya', 'de61b900-30d9-4a12-9089-8c7c219fe6a6'],
  ['Colombia', 38, 'Yohana Espitia', '0c34dcc2-65b1-4a98-91ca-05b2024f4378', colombia3],
  ['Colombia', 38, 'Ruby Acosta Gomez', '8460e804-b380-433c-9f02-2e66621fe602', colombia2],
  ['Colombia', 38, 'Mary Alexandra Mora Hernández', '32616cc0-f107-46a6-867a-fb419e7a743e', colombia1],
  ['Côte d’Ivoire', 85, 'Sadia Pierre Ble', 'ea520a66-c2ee-469f-9252-a0a58efcadc2', coteDiVoire],
  ['Croatia', 56, 'Boris Špalj', '3a11ae24-fc0e-4a6c-b407-19df2b59b3ed'],
  ['Cyprus', 59, 'Dimitrios Tzavaras', 'feaed170-ab4b-43ff-8f8e-a6f39b09d28b'],
  ['Czech Republic', 60, 'Jan Hoffer', '178b2d1d-5642-48aa-a3b7-22d361fadc93'],
  ['Democratic Republic of the Congo', 52, 'Robert Mambo Liripa', '24ba7bf9-efba-4302-bebb-3fc23f9329ef'],
  ['Denmark', 61, 'Philip Winther Soendergaard', 'c9f8a591-e3b0-11ea-bc15-005056890e85', philipWintherSoendergaard],
  ['Ecuador', 65, 'Silvia Alexandra Rivera Badillo', '3bf983d6-b38b-4207-9af8-24e24fb9326e', silviaRivera],
  ['Egypt', 66, 'Mostafa Ahmed Sabry', '85ebde65-aab0-4dc4-8300-59282f333e8e', mustafaSabryEgypt],
  ['El Salvador', 67, 'Jose Belarmino Lopez Ortiz', '2aaf0c77-a9f0-4257-b5c8-6d09772ef6e6', elSalvador],
  ['France', 76, 'Marie Filoreau', '6c0bd452-60a6-4968-aebd-fb11e90fb7e9', france],
  ['Estonia', 70, 'Anita Jemeljanova', 'ef29329f-4241-4b54-a94a-612a37a6ff4a', balticCountries1],
  ['Estonia', 70, 'Stanislavs Gorins', 'ef29329f-4241-4b54-a94a-612a37a6ff4a', balticCountries2],
  ['Finland', 75, 'Mika Holappa', '64582fa5-79f3-4da0-9329-163690ebb781', finland],
  ['Germany', 83, 'Wilhelm Enns', 'fdae3ea1-9250-48e2-a5cc-54147fdfafbd'],
  ['Guatemala', 91, 'José Miguel de Aguila Mendizabal', 'aa17438d-e3b0-11ea-bc15-005056890e85', joseMendizabal],
  ['Greece', 86, 'Dimitrios Tzavaras', '0b704820-c588-4588-90da-95825c36bed4', greece],
  ['Hungary', 101, 'Janos Tasi', '8b4a4cf3-8471-4aab-a32f-0a1d68f25205', hungary],
  ['Iceland', 102, 'Gunnar Jonasson', '9e917f16-8038-4e5e-afa9-0bf893fed0a7', iceland],
  ['India', 103, 'Danakonda Dzanardhana', '1f8e8a56-d788-4545-ac79-4964635ead46', india],
  ['Indonesia', 104, 'Nyoman Artaya', 'ff7a7f84-d50c-49dc-b694-8728da9dd89b', indonesia],
  ['Iraq', 106, 'Khabat Aziz', '2693a60e-ed15-4ac3-ac6c-9596d5b38fca', iraq],
  ['Israel', 109, 'Wahbi Kheit', '892b1bdb-eaf7-4729-bd27-cf4a7c1046ed', israelPalestine],
  ['Italy', 110, 'Renato Bagarolo', 'daa4f57e-72e9-4ba9-bb34-7118e5813e3c', renatoBagaroloItaly],
  ['Italy', 110, 'Gaetano Lavolpicella', 'be4c8953-dc6f-4de8-beac-d06564da76cd', italy5],
  ['Italy', 110, 'Luigi Vallone', '6851b21c-47d2-4300-aa30-e6347443932e', italy1],
  ['Italy', 110, 'Andrea Bini', 'c6e51494-9c62-4aa2-a8e2-2dfcefe6d609', italy4],
  ['Italy', 110, 'Massimo Bortoloni', '156ad9ef-c19e-4a54-a74c-54eeaf8708ea', italy3],
  ['Japan', 112, 'José Alberto Sugawara', '56940968-4c5f-4660-aea2-d663a9980b6c', japan],
  ['Kazakhstan', 115, 'Askar Yechshanov', '92c76e09-bbfb-4f65-b6e5-6d926395d2b0', kazakhstan],
  ['Kosovo', 250, 'Etleva Treska', '4b2a9bcb-e3cd-4726-8505-57aec8ee9d10'],
  ['Kyrgyzstan', 121, 'Kaliya Toktogulova', '518fb049-5109-4deb-ae67-0c569fff030c', kyrgyzstan],
  ['Latvia', 123, 'Anita Jemeljanova', 'ef29329f-4241-4b54-a94a-612a37a6ff4a', balticCountries1],
  ['Latvia', 123, 'Stanislavs Gorins', 'ef29329f-4241-4b54-a94a-612a37a6ff4a', balticCountries2],
  ['Liechtenstein', 128, 'Wilhelm Enns', '317170c8-695f-4b03-b5fc-541a6f869276'],
  ['Lithuania', 129, 'Anita Jemeljanova', 'ef29329f-4241-4b54-a94a-612a37a6ff4a', balticCountries1],
  ['Lithuania', 129, 'Stanislavs Gorins', 'ef29329f-4241-4b54-a94a-612a37a6ff4a', balticCountries2],
  ['Malawi', 134, 'Wilfred Chisepu Phiri', 'f51aeeba-8e64-44fd-bda2-807f09f5d56a'],
  ['Malaysia', 135, 'Francias Tham', 'e856d894-a881-4a51-ad5e-7b6a310f250f'],
  ['Malta', 138, 'Renato Bagarolo', 'ebc9edbe-e13f-11ea-bc15-005056890e85', renatoBagaroloMaltaSanMarino],
  ['Moldova', 146, 'Tatiana Rusu', 'e0aa35d0-e140-11ea-bc15-005056890e85', rusuTatiana],
  ['Mongolia', 148, 'Erdenechimeg Luvsannamjil', '0c61e902-3a97-4b01-8422-4b6584537669'],
  ['Morocco', 151, 'Mounir Bakhari', '21c55424-ef1b-41cc-896d-73503e175f5d', morocco],
  ['Myanmar', 153, 'Nyunt Win', 'fd06f94a-dea0-47cb-8cd6-560518e25be5'],
  ['Netherlands', 157, 'Marien Almekinders', '20e99eea-0a48-41d3-8368-9b0e0648706e'],
  ['New Zealand', 159, 'Jane Jiang', '646bf4ac-e1f4-460c-b8b4-84c0319708c9', newZealand],
  ['Nigeria', 162, 'Hudson Afor Okolo', '3765d12c-2ce8-4449-a20f-957e3129aa24', nigeria],
  ['North Macedonia', 132, 'Stefcho Kochoski', '36870ec2-90b0-46f6-8432-1414a5977301'],
  ['Norway', 166, 'Tommy Eriksson', '3f9e98e4-880d-44dd-9410-c0526375a40e', swedenAndNorway1],
  ['Norway', 166, 'Roland Andersson', '3f9e98e4-880d-44dd-9410-c0526375a40e', swedenAndNorway2],
  ['Pakistan', 168, 'Mujeeb Ur Rehman', '3908c824-e26a-4be8-a4b7-e7f8e3eb8a82', pakistan],
  ['Palestine State', 170, 'Dr. Wahbi Kheit', '892b1bdb-eaf7-4729-bd27-cf4a7c1046ed', israelPalestine],
  ['Panama', 171, 'Erick Rueda', '70fca4fd-08d5-4a9b-8700-5fafde85f02e', panama],
  ['Paraguay', 173, 'Ninelle Shtivelberg', 'db9ef5be-e3b0-11ea-bc15-005056890e85', paraguay],
  ['Peru', 174, 'Maria Liset Silva Palacios', '33fdfc9a-de1f-4062-82ca-37704e72bee5', peru],
  ['Philippines', 175, 'Mitchai Dela Cruz', '5e8131da-bbc8-445d-ae3f-d68d5bcb0a8f', philippines],
  ['Poland', 177, 'Adam Gruszka', '1074a870-2485-4aaa-b924-3c89c5fe4796', poland],
  ['Serbia', 197, 'Predrag Rmus', 'c0c67afc-e140-11ea-bc15-005056890e85', predragRmus],
  ['Montenegro', 149, 'Predrag Rmus', 'c0c67afc-e140-11ea-bc15-005056890e85', predragRmus],
  ['San Marino', 193, 'Renato Bagarolo', 'ebc9edbe-e13f-11ea-bc15-005056890e85', renatoBagaroloMaltaSanMarino],
  ['Slovenia', 203, 'Polona Ajdic', '950b8c44-46fc-4ecd-9451-35413a07e4f3', slovenia],
  ['South Korea', 119, 'Ahn Kwangja', '63e37637-ad49-4a5a-ab77-448efdb319d5', ahnKwangja],
  ['South Korea', 119, 'Young Ju Kim', '80e0389f-005a-4504-8235-7648290308dd', youngJuKim],
  ['Spain', 209, 'Pedro Pardo Quesada', '381798ee-a301-4b90-9607-90206f5cb776', spain1],
  ['Spain', 209, 'Mary Santos', '381798ee-a301-4b90-9607-90206f5cb776', spain2],
  ['Somalia', 205, 'Ali Abdulkadir Hassan', 'cf7c79b8-e3b0-11ea-bc15-005056890e85', aliAbdulkadir],
  ['Sweden', 215, 'Tommy Eriksson', '3f9e98e4-880d-44dd-9410-c0526375a40e', swedenAndNorway1],
  ['Sweden', 215, 'Roland Andersson', '3f9e98e4-880d-44dd-9410-c0526375a40e', swedenAndNorway2],
  ['Switzerland', 216, 'Wilhelm Enns', '4c661a04-7257-4ba0-a36b-9f6366b89d0f'],
  ['Taiwan', 218, 'Jing-Jing Liang', '66da2044-d7f3-4e78-90d7-0cfb19731094', taiwan],
  ['Tanzania', 220, 'Derick Machuni', '6fc38876-c93b-42fd-bb59-bcfef481b79c', tanzania],
  ['Thailand', 221, 'Ubon Pornraksa', '162dbe7b-5ecc-45e8-9e33-383e443ef307', thailand],
  ['Turkey', 228, 'Iltfat Aliyev', 'de618e47-c202-48a1-90d8-0afcc6e98d6f', iltfatAliyev],
  ['Turkey', 228, 'Baha Eryurekliler', 'ce0f0231-e140-11ea-bc15-005056890e85', bahaEryurekliler],
  ['Uganda', 232, 'Gonzaga Ssebadduka', '2d7ba78f-4527-48dc-ac19-754db7b3be7b', uganda],
  ['Uganda', 232, 'Kiiza Octus', 'ae42de45-19a5-4325-af14-8f97c332ff8e'],
  ['Ukraine', 233, 'Olha Narevach', '77cb5ec4-8f79-4df0-82fd-9a46a71723b0', ukraine],
  ['Uzbekistan', 239, 'Sholpan Baltabayeva', 'f50a1bd3-5460-4dbc-8f86-2e26b5699309'],
  ['Venezuela', 241, '', 'bb0e6abd-78bf-42f0-b668-b91421228ef6'],
  ['Yemen', 247, 'Ahmed Al Oussoudy', '69c1f2a2-dbad-4dca-aab6-bdf8a97be79c', yemen],
  ['Trinidad and Tobago', 226, 'Ivan Mark', '', ivanMarkCaribbean],
  ['Guyana', 96, 'Ivan Mark', 'd632a5b5-e140-11ea-bc15-005056890e85', ivanMarkCaribbean],
  ['Barbados', 20, 'Ivan Mark', 'd632a5b5-e140-11ea-bc15-005056890e85', ivanMarkCaribbean],
  ['Suriname', 212, 'Melina Martosoedarmo', '9e4e9e68-c561-4442-b8e8-8c32527a280f'],
  ['Suriname', 212, 'Ivan Mark', 'd632a5b5-e140-11ea-bc15-005056890e85', ivanMarkCaribbean],
  ['Jamaica', 111, 'Ivan Mark', 'd632a5b5-e140-11ea-bc15-005056890e85', ivanMarkCaribbean],
  ['Ecuador', 65, 'Abel Benigno Díaz Salirrosas', 'c4d7fd9c-e3b0-11ea-bc15-005056890e85', abelDiazEcuador],
  ['Portugal', 178, 'Eugenio Rodrigues', 'cae0b9a8-e13f-11ea-bc15-005056890e85', eugenioRodriguesPortugal],
  ['Ghana', 84, 'Desmond Boateng', 'db1405b8-e140-11ea-bc15-005056890e85', desmondBoateng],
  ['Ghana', 84, 'Sandra Una Boakye', 'df1bb2cc-e140-11ea-bc15-005056890e85', sandraUnaBoakye],
];

const convertData = () =>
  data.reduce((acc, [country, countryId, userName, userId, userImg]) => {
    // Check if the accumulator already has an entry for the country
    // If not, create a new entry for the country with its countryId and an empty 'managers' array
    if (!acc[country]) acc[country] = { countryId, managers: [] };

    // Add the current manager's data to the 'managers' array for the corresponding country
    acc[country].managers.push({
      userName,
      userId,
      userImg: userImg ?? null,
      managerName: userName,
      managerImg: userImg ?? null,
    });

    return acc;
  }, {});

export const managerProfiles = Object.entries(convertData()).map(([country, { countryId, managers }]) => ({
  country,
  countryId,
  managers,
}));
